<div class="wrapper">
    <div class="page-header" style="background-image: url('./assets/img/login-image.jpg');">
        <div class="filter"></div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 mr-auto ml-auto">
                      <div class="card card-register">
                          <h3 class="title">Welcome</h3>
                          <div class="social-line text-center">
                              <a href="#pablo" class="btn btn-neutral btn-facebook btn-just-icon">
                                  <i class="fab fa-facebook fa-3x"></i>
                              </a>
                              <a href="#pablo" class="btn btn-neutral btn-google btn-just-icon">
                                  <i class="fas fa-google-plus"></i>
                              </a>
                              <a href="#pablo" class="btn btn-neutral btn-twitter btn-just-icon">
                                  <i class="fas fa-twitter"></i>
                              </a>
                          </div>
                          <form class="register-form">
                              <label>Email</label>
                              <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                      <i class="nc-icon nc-email-85"></i>
                                  </span>
                                </div>

                                  <input type="text" class="form-control" placeholder="Email" (focus)="focus=true" (blur)="focus=false" >
                              </div>

                              <label>Password</label>
                              <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus1===true}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                      <i class="nc-icon nc-key-25"></i>
                                  </span>
                                </div>
                                  <input type="text" class="form-control" placeholder="Password" (focus)="focus1=true" (blur)="focus1=false" >
                              </div>
                              <button class="btn btn-danger btn-block btn-round">Register</button>
                          </form>
                          <div class="forgot">
                              <a href="#" class="btn btn-link btn-danger">Forgot password?</a>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="footer register-footer text-center">
                    <h6>&copy;{{test | date: 'yyyy'}}, made with <i class="fas fa-heart heart"></i> by Creative Tim</h6>
                </div>
            </div>
    </div>
</div>
