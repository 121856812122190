<footer class="footer">
    <div class="container">
        <div class="row">
            <span>PuliServicios © {{test | date: 'yyyy'}}</span>
            <div class="credits ml-auto">
                <span class="copyright">
                   <span>Hecho con 💚 por </span>
                   <a href="https://oscarbeses.com/">Oscar Beses 👨‍💻✌</a> 
                   <span style="display:none;">tema de: <a href="https://www.creative-tim.com">Creative Tim</a></span>
                </span>
            </div>
        </div>
    </div>
</footer>
