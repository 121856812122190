<div class="wrapper" style="scroll-behavior: smooth;">
    <div class="page-header">
        <div class="content-center">
            <div class="container">
                <div class="title-brand">
                    <h1 class="presentation-title">Puli Servicios</h1>
                    <div class="moving-clouds" style="background-image: url('assets/img/pulidora.png'); "></div>
                </div>
                <h2 class="presentation-subtitle text-center">
                    Pulido, vitrificado y abrillantado de suelos en Valencia y alrededores
                </h2>
            </div>
        </div>
    </div>
    <a id="buttonToMain" class="w-100 text-center m-auto" (click)="scrollToMain()">
        <i class="fas fa-arrow-alt-circle-down fa-5x"></i>
    </a>
    <main>
        <app-basicelements></app-basicelements>
        <div class="container">
            <div class="tim-title">
                <h2 class="pt-4">Servicios</h2>
            </div>
        </div>
        <app-carousel [titulo]= "titHidraul" [descripcion]="descHidraul" [fotos]="fotosCarouselHidraulico"></app-carousel>
        <app-carousel [titulo]= "titTerrazoRest" [descripcion]="descTerrazoRest" [fotos]="fotosCarouselTerrazoRest" order="-1"></app-carousel>
        <app-carousel [titulo]= "titMarmol" [descripcion]="descMarmol" [fotos]="fotosCarouselMarmol"></app-carousel>
        <app-carousel [titulo]= "titTerrazoBajo" [descripcion]="descTerrazoBajo" [fotos]="fotosCarouselTerrazoBajo" order="-1"></app-carousel>
        <app-carousel [titulo]= "titMadera" [descripcion]="descMadera" [fotos]="fotosCarouselMadera"></app-carousel>
        <app-contacto></app-contacto>
    </main>
</div>