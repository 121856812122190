<div class="section section-buttons contacto" style="background-color: lightgrey;">
    <div class="container">
        <div itemscope itemtype="http://schema.org/LocalBusiness">
            <h2>Llámanos y pide tu presupuesto sin compromiso</h2>
            <a class="telefono" href="tel:+34658456268">
                <i class="fas fa-mobile-alt fa-lg"></i>
                <span itemprop="telephone"> 658 456 268</span>
            </a>
        </div>
        <div>
            <h2>Háblanos por WhatsApp</h2>
            <a class="my-0 wha" rel="tooltip" title="Háblanos por WhatsApp" data-placement="bottom" 
                href="https://api.whatsapp.com/send/?phone=34658456268&text=Hola%2C+me+interesa+solicitar+un+presupuesto&app_absent=0" 
                target="_blank">
                <i class="fab fa-whatsapp fa-lg"></i>
                <span> PuliServicios</span>
            </a>
        </div>
        <div>
            <h2>o por Facebook</h2>
            <a class="my-0" rel="tooltip" title="Visitanos en Facebook" data-placement="bottom" 
                href="https://www.facebook.com/Puliservicios-397394460596334/" target="_blank">
                <i class="fab fa-facebook fa-lg"></i>
                <span> PuliServicios</span>
            </a>
        </div>
    </div>
</div>