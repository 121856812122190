<article class="post style1">

  <div class="page-carousel">
    <ngb-carousel [interval]="8000">
      <ng-template ngbSlide *ngFor="let foto of fotos">
        <img *ngIf="foto.endsWith('.jpeg')" src="{{foto}}" />
        <video *ngIf="foto.endsWith('.mp4')" controls autoplay [muted]="'muted'">
          <source src="{{foto}}" type="video/mp4">
        </video>
      </ng-template>
    </ngb-carousel>
  </div>

  <div class="content" [style.order]="order">
    <div class="inner" [class.align-right]="order == '-1'">
      <header>
        <h2>{{titulo}}</h2>
      </header>
      <p>
        {{descripcion}}
      </p>
    </div>
  </div>
  
</article>
