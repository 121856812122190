<div class="section margen-negativo">
    <div class="container">
        <div class="tim-title">
            <h2 class="pt-5">Sobre Nosotros</h2>
        </div>
        <div>
            <p>Somos <b>técnicos diplomados</b> por el Instituto Tecnológico Español de Limpiezas. 
            <br/>
            Con <b>mas de 15 años de experiencia</b> en pulido y abrillantado de todo tipo de suelos.</p>
        </div>
    </div>
</div>
